import React from 'react'

const Logo = ({ width = 35, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 74 38"
  >
    <title>Gala</title>
    <g fill="none">
      <path
        fill="#EBEAE4"
        d="M22.3 13.1L17.7 12 17.7 12.4C19.1 13.5 19.7 14.9 19.7 16.3 19.7 20 16.1 22.5 12.3 22.5 11.4 22.5 10.4 22.3 9.6 22.1 8.8 22.3 8.2 23.1 8.2 23.7 8.2 24.7 9.5 25.1 11.4 25.8L13.2 26.3C16.8 27.4 19.2 28.7 19.2 31.6 19.2 34.5 15.6 37.5 8.7 37.5 4.1 37.5.9 35.8.9 32.9.9 30.5 3.7 29 6.3 28.3L6.3 28C5.4 27.5 4.6 26.6 4.6 25.4 4.6 23.6 6 22.4 8.1 21.7L8.1 21.4C6.4 20.4 5.3 18.5 5.3 16.5 5.3 12.1 9.1 9 13.6 9 15.3 9 17.3 9.5 18.2 9.8L23.1 9.7 22.3 13.1zM15.9 13.3C15.9 12.2 14.3 11 13 11 12.4 11 12 11.2 11.8 11.4 10.5 12.7 9.7 15.8 9.7 17.8 9.7 18.9 10.2 19.9 10.6 20.3 10.9 20.6 11.3 20.8 11.8 20.8 12.7 20.8 13.4 20.4 13.9 19.8 15.3 18.4 15.9 15.2 15.9 13.3zM15.7 33C15.7 31.8 14.4 31 11.4 30 10 29.6 8.7 29.1 7.5 28.6 5.8 29.4 4.7 30.6 4.7 32.1 4.7 34.1 7.1 35.5 10.8 35.5 13.6 35.5 15.7 34.4 15.7 33zM36.2 11.9L36.5 11.9 37.6 8.9 41.6 8.9 39.3 16 37.2 24.7 38.3 25.1 41.2 23.1 41.9 23.9C40.7 25.9 38.5 27.8 36.4 27.8 35.4 27.8 32.9 26.9 32.9 26.2 32.9 25.4 34.3 21.8 35.1 19.4L34.7 19.4C31.7 24.4 29.9 27.8 27.2 27.8 24.7 27.8 22.8 26 22.8 22.5 22.8 18.6 24.9 14.2 27.5 11.6 29.1 10 31.1 8.9 32.9 8.9 34.8 8.9 35.8 10.3 36.2 11.9zM35.7 14.4C34.8 12.9 32.8 11.4 31.4 11.4 30.9 11.4 30.5 11.6 30.1 12 28.5 13.6 27 18.2 27 21.3 27 23.3 27.8 24.4 29 24.4 30.5 24.4 31.8 22.1 34.7 17.5L35.7 14.4z"
      />
      <path
        fill="#EBEAE4"
        d="M36.2 11.9L36.5 11.9 37.6 8.9 41.6 8.9 39.3 16 37.2 24.7 38.3 25.1 41.2 23.1 41.9 23.9C40.7 25.9 38.5 27.8 36.4 27.8 35.4 27.8 32.9 26.9 32.9 26.2 32.9 25.4 34.3 21.8 35.1 19.4L34.7 19.4C31.7 24.4 29.9 27.8 27.2 27.8 24.7 27.8 22.8 26 22.8 22.5 22.8 18.6 24.9 14.2 27.5 11.6 29.1 10 31.1 8.9 32.9 8.9 34.8 8.9 35.8 10.3 36.2 11.9zM35.7 14.4C34.8 12.9 32.8 11.4 31.4 11.4 30.9 11.4 30.5 11.6 30.1 12 28.5 13.6 27 18.2 27 21.3 27 23.3 27.8 24.4 29 24.4 30.5 24.4 31.8 22.1 34.7 17.5L35.7 14.4zM52.4 23.9L51.7 23.1 48.9 25.1 47.8 24.7 53.1 0 45.3 2 45.1 3.1 47.3 4.2C47.8 4.4 47.9 4.9 47.8 5.5L43.5 26.2C43.4 26.7 46 27.8 46.9 27.8 48.9 27.8 51.2 25.9 52.4 23.9z"
      />
      <path
        fill="#EBEAE4"
        d="M52.4 23.9L51.7 23.1 48.9 25.1 47.8 24.7 53.1 0 45.3 2 45.1 3.1 47.3 4.2C47.8 4.4 47.9 4.9 47.8 5.5L43.5 26.2C43.4 26.7 46 27.8 46.9 27.8 48.9 27.8 51.2 25.9 52.4 23.9zM67.5 11.9L67.8 11.9 68.9 8.9 72.9 8.9 70.5 16 68.4 24.7 69.5 25.1 72.4 23.1 73.1 23.9C71.9 25.9 69.7 27.8 67.6 27.8 66.6 27.8 64.1 26.9 64.1 26.2 64.1 25.4 65.5 21.8 66.3 19.4L65.9 19.4C62.9 24.4 61.1 27.8 58.4 27.8 55.9 27.8 54 26 54 22.5 54 18.6 56.1 14.2 58.7 11.6 60.3 10 62.3 8.9 64.1 8.9 66.1 8.9 67 10.3 67.5 11.9zM66.9 14.4C66 12.9 64 11.4 62.6 11.4 62.1 11.4 61.7 11.6 61.3 12 59.7 13.6 58.2 18.2 58.2 21.3 58.2 23.3 59 24.4 60.2 24.4 61.7 24.4 63 22.1 65.9 17.5L66.9 14.4z"
      />
      <path
        fill="#EBEAE4"
        d="M67.5,11.9 L67.8,11.9 L68.9,8.9 L72.9,8.9 L70.5,16 L68.4,24.7 L69.5,25.1 L72.4,23.1 L73.1,23.9 C71.9,25.9 69.7,27.8 67.6,27.8 C66.6,27.8 64.1,26.9 64.1,26.2 C64.1,25.4 65.5,21.8 66.3,19.4 L65.9,19.4 C62.9,24.4 61.1,27.8 58.4,27.8 C55.9,27.8 54,26 54,22.5 C54,18.6 56.1,14.2 58.7,11.6 C60.3,10 62.3,8.9 64.1,8.9 C66.1,8.9 67,10.3 67.5,11.9 Z M66.9,14.4 C66,12.9 64,11.4 62.6,11.4 C62.1,11.4 61.7,11.6 61.3,12 C59.7,13.6 58.2,18.2 58.2,21.3 C58.2,23.3 59,24.4 60.2,24.4 C61.7,24.4 63,22.1 65.9,17.5 L66.9,14.4 Z"
      />
      <path
        fill="#8764EA"
        d="M72,31 L20.5,31 L20.5,31.5 C20.5,32.3 20.3,33.2 19.8,34 L72,34 L72,31 Z M14.5,33 C14.5,32.7 13.9,32.1 11.1,31.2 C10.9,31.1 10.7,31.1 10.5,31 L6.4,31 C6.1,31.3 6,31.7 6,32.1 C6,32.9 7,33.7 8.6,34 L12.8,34 C13.9,33.7 14.5,33.2 14.5,33 Z"
      />
    </g>
  </svg>
)

export default Logo
